export default defineNuxtRouteMiddleware((to) => {
  const user = useUserStore()
  const requiredRole = to.meta.requiredRole || "user"

  if (user.role === requiredRole || requiredRole === "any") {
    return
  }

  if (requiredRole === "guest") {
    return navigateTo("/")
  }

  if (requiredRole === "user") {
    return navigateTo("/auth")
  }
})
