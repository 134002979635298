import { vTooltip, options } from "floating-vue"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("tooltip", vTooltip)

  options.themes.tooltip = {
    ...options.themes.tooltip,
    distance: 7,
    overflowPadding: 10,
    triggers: ["hover"],
    popperTriggers: ["hover"],
    hideTriggers: ["hover"],
  }
})
