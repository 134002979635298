import * as Sentry from "@sentry/vue"

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  Sentry.init({
    release: `${config.public.name}@${config.public.version}`,
    enabled: process.env.NODE_ENV === "production",
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDSN,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === "xhr" && breadcrumb.data?.url) {
        try {
          const url = new URL(breadcrumb.data.url)
          const paramsToHide = ["st", "lt"]
          for (const param of paramsToHide) {
            if (url.searchParams.has(param)) {
              url.searchParams.set(param, "hidden")
            }
          }
          breadcrumb.data.url = url.toString()
        } catch {
          // no sentry documentation for url in breadcrumbs, so URL constructor may throw an error
        }
      }

      return breadcrumb
    },
  })
})
