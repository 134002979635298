import { AppID } from "~/types/api"

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore()
  const appID = to.query.authAppID as AppID
  if (Object.values(AppID).includes(appID)) {
    authStore.appID = appID
  }

  const user = useUserStore()
  if (user.isAuthorized && authStore.appID !== AppID.PublisherID) {
    const response = await login({ app_id: authStore.appID })
    if (response.redirect_uri) {
      return navigateTo(
        {
          replace: true,
          path: response.redirect_uri,
        },
        {
          external: true,
        }
      )
    }
  }

  if (appID) {
    return navigateTo({
      replace: true,
      path: to.fullPath,
      query: {
        ...to.query,
        authAppID: undefined,
      },
    })
  }
})
