import { AppID } from "~/types/api"

export const useAuthStore = defineStore("auth", () => {
  const email = ref("") // exclusively for filling email on login page after signing up
  const appID = ref(AppID.PublisherID)

  return {
    email,
    appID,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
