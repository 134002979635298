<template>
  <notifications
    position="top center"
    :close-on-click="false"
    :width="400"
    animation-name="vn"
    :speed="300"
  >
    <template #body="{ item, close }">
      <v-notification
        :key="item.createdAt"
        :type="item.type"
        class="mb-2"
        closeable
        @close="close"
      >
        <v-html :content="item.text" />
      </v-notification>
    </template>
  </notifications>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.vue-notification-group {
  @apply pt-3;
}

.vn-move,
.vn-enter-active,
.vn-leave-active {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

.vn-enter-from,
.vn-leave-to {
  opacity: 0;
}

.vn-leave-active {
  position: absolute;
}
</style>
