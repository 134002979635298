import type { RequestOptions } from "core/types/api"
import type { DocumentType, PublisherProfile, Sex, StartKYCVerificationResponse } from "~/types/api"

export function getPublisherProfile({ options }: { options?: RequestOptions } = {}) {
  return publisherIDAPI.get<PublisherProfile>("get_publisher_profile", options)
}

export function editPublisherProfile(data: {
  email: string
  name: string
  surname: string
  phone: string
  birthday_date: string
  document_country_code: string
  document_expiration_date: string
  document_issuance_date: string
  document_number: string
  document_type: null | DocumentType
  tax_number: string
  nationality_country_code: string
  sex: null | Sex
  address: string
}) {
  return publisherIDAPI.post<PublisherProfile>("edit_publisher_profile", { data })
}

export function startKYCVerification() {
  return publisherIDAPI.get<StartKYCVerificationResponse>("start_kyc_verification")
}
