import { default as _91ticket_93EcoF9s1xeCMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/2fa/enable/[ticket].vue?macro=true";
import { default as _91ticket_933Xu3dsWyYMMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/2fa/verify/[ticket].vue?macro=true";
import { default as indexXdqUWV9mFwMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/index.vue?macro=true";
import { default as _91ticket_93ZVwY2LgxnQMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/recover-password/[ticket].vue?macro=true";
import { default as index4cnejB3MZlMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/recover-password/index.vue?macro=true";
import { default as sign_45updpSSoAZgkjMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/sign-up.vue?macro=true";
import { default as change_45passwordfOV9WJhE1vMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/change-password.vue?macro=true";
import { default as indexfgr5sBD8auMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/index.vue?macro=true";
import { default as overviewRBWkk0YkfgMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/overview.vue?macro=true";
import { default as editrM3uraPVh7Meta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/profile/edit.vue?macro=true";
import { default as verify_45identitygPRvg8PD6XMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/verify-identity.vue?macro=true";
export default [
  {
    name: _91ticket_93EcoF9s1xeCMeta?.name ?? "auth-2fa-enable-ticket",
    path: _91ticket_93EcoF9s1xeCMeta?.path ?? "/auth/2fa/enable/:ticket()",
    meta: _91ticket_93EcoF9s1xeCMeta || {},
    alias: _91ticket_93EcoF9s1xeCMeta?.alias || [],
    redirect: _91ticket_93EcoF9s1xeCMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/2fa/enable/[ticket].vue").then(m => m.default || m)
  },
  {
    name: _91ticket_933Xu3dsWyYMMeta?.name ?? "auth-2fa-verify-ticket",
    path: _91ticket_933Xu3dsWyYMMeta?.path ?? "/auth/2fa/verify/:ticket()",
    meta: _91ticket_933Xu3dsWyYMMeta || {},
    alias: _91ticket_933Xu3dsWyYMMeta?.alias || [],
    redirect: _91ticket_933Xu3dsWyYMMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/2fa/verify/[ticket].vue").then(m => m.default || m)
  },
  {
    name: indexXdqUWV9mFwMeta?.name ?? "auth",
    path: indexXdqUWV9mFwMeta?.path ?? "/auth",
    meta: indexXdqUWV9mFwMeta || {},
    alias: indexXdqUWV9mFwMeta?.alias || [],
    redirect: indexXdqUWV9mFwMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: _91ticket_93ZVwY2LgxnQMeta?.name ?? "auth-recover-password-ticket",
    path: _91ticket_93ZVwY2LgxnQMeta?.path ?? "/auth/recover-password/:ticket()",
    meta: _91ticket_93ZVwY2LgxnQMeta || {},
    alias: _91ticket_93ZVwY2LgxnQMeta?.alias || [],
    redirect: _91ticket_93ZVwY2LgxnQMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/recover-password/[ticket].vue").then(m => m.default || m)
  },
  {
    name: index4cnejB3MZlMeta?.name ?? "auth-recover-password",
    path: index4cnejB3MZlMeta?.path ?? "/auth/recover-password",
    meta: index4cnejB3MZlMeta || {},
    alias: index4cnejB3MZlMeta?.alias || [],
    redirect: index4cnejB3MZlMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/recover-password/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45updpSSoAZgkjMeta?.name ?? "auth-sign-up",
    path: sign_45updpSSoAZgkjMeta?.path ?? "/auth/sign-up",
    meta: sign_45updpSSoAZgkjMeta || {},
    alias: sign_45updpSSoAZgkjMeta?.alias || [],
    redirect: sign_45updpSSoAZgkjMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordfOV9WJhE1vMeta?.name ?? "change-password",
    path: change_45passwordfOV9WJhE1vMeta?.path ?? "/change-password",
    meta: change_45passwordfOV9WJhE1vMeta || {},
    alias: change_45passwordfOV9WJhE1vMeta?.alias || [],
    redirect: change_45passwordfOV9WJhE1vMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: indexfgr5sBD8auMeta?.name ?? "index",
    path: indexfgr5sBD8auMeta?.path ?? "/",
    meta: indexfgr5sBD8auMeta || {},
    alias: indexfgr5sBD8auMeta?.alias || [],
    redirect: indexfgr5sBD8auMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/index.vue").then(m => m.default || m)
  },
  {
    name: overviewRBWkk0YkfgMeta?.name ?? "overview",
    path: overviewRBWkk0YkfgMeta?.path ?? "/overview",
    meta: overviewRBWkk0YkfgMeta || {},
    alias: overviewRBWkk0YkfgMeta?.alias || [],
    redirect: overviewRBWkk0YkfgMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/overview.vue").then(m => m.default || m)
  },
  {
    name: editrM3uraPVh7Meta?.name ?? "profile-edit",
    path: editrM3uraPVh7Meta?.path ?? "/profile/edit",
    meta: editrM3uraPVh7Meta || {},
    alias: editrM3uraPVh7Meta?.alias || [],
    redirect: editrM3uraPVh7Meta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: verify_45identitygPRvg8PD6XMeta?.name ?? "verify-identity",
    path: verify_45identitygPRvg8PD6XMeta?.path ?? "/verify-identity",
    meta: verify_45identitygPRvg8PD6XMeta || {},
    alias: verify_45identitygPRvg8PD6XMeta?.alias || [],
    redirect: verify_45identitygPRvg8PD6XMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/pages/verify-identity.vue").then(m => m.default || m)
  }
]