import type { AppID } from "~/types/api"
import type {
  ChangePasswordResponse,
  Get2FAEnableSecretsResponse,
  LoginResponse,
  SignUpResponse,
  StartPasswordRecoveryResponse,
  Verify2FAResponse,
} from "~/types/api/auth"

export function login(data: { app_id: AppID; email?: string; password?: string; "g-recaptcha-response"?: string }) {
  return publisherIDAPI.post<LoginResponse>("login", { data })
}

export function startPasswordRecovery(data: { email: string; "g-recaptcha-response": string }) {
  return publisherIDAPI.post<StartPasswordRecoveryResponse>("recovery", { data })
}

export function finishPasswordRecovery(data: { email: string; ticket: string; code: string }) {
  return publisherIDAPI.post("recovery", { data })
}

export function signup(data: { app_id: AppID; name: string; surname: string; phone: string; email: string; "g-recaptcha-response": string }) {
  return publisherIDAPI.post<SignUpResponse>("signup", { data })
}

export function verify2FA(data: { ticket: string; "2fa_code": string }) {
  return publisherIDAPI.post<Verify2FAResponse>("verify_2fa", { data })
}

export function get2FAEnableSecrets(params: { ticket: string }) {
  return publisherIDAPI.get<Get2FAEnableSecretsResponse>("get_2fa_enable_secrets", { params })
}

export function changePassword(data: { password: string; new_password: string; "2fa_code": string }) {
  return publisherIDAPI.post<ChangePasswordResponse>("change_password", { data })
}
