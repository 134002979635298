import pkg from "./package.json"

export default {
  name: "appdb-publisher/id",
  version: pkg.version,
  siteName: "Publisher ID",
  publisherIDAPIBaseURL: "https://publisheridapi.dbservices.to/v1.0",
  publisherAreaURL: "https://publisher.appdb.to",
  recaptchaKey: "6LfT848pAAAAAAyhNXyA_MlMjiYx4AC3gjdX5qc7",
  sentryDSN: "https://2205bb50dae620fa67ad9aba9a9b99f2@o4507169115471872.ingest.de.sentry.io/4507169254080592",
}
