import revive_payload_client_nUGef6glBa from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.0_ioredis@5_idqrscfuumkpwwnd5s3sbo7foy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_clwtyRzhUA from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.0_ioredis@5_idqrscfuumkpwwnd5s3sbo7foy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8ILKcSZw7h from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.0_ioredis@5_idqrscfuumkpwwnd5s3sbo7foy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_qE03i9JlSr from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.0_ioredis@5_idqrscfuumkpwwnd5s3sbo7foy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_bPmvcT7J1j from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.0_ioredis@5_idqrscfuumkpwwnd5s3sbo7foy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_d0NuRMK76x from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.1_typescript@5.4.5_vue@3.4.26_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/.nuxt/components.plugin.mjs";
import prefetch_client_bnopKMg6f5 from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.0_ioredis@5_idqrscfuumkpwwnd5s3sbo7foy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/.nuxt/primevue-plugin.mjs";
import plugin_client_vAlB0x9EaS from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt-primevue@0.3.1_rollup@4.17.1_vue@3.4.26_typescript@5.4.5_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_client_Ko7ITHlbQv from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.17.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_jw9DC0cPzU from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.1_vue@3.4.26_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_smILZi5sC6 from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.0_ioredis@5_idqrscfuumkpwwnd5s3sbo7foy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_sentry_axzTOhSqTR from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/01.sentry.ts";
import _02_tokens_wrFGe2jZkz from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/02.tokens.ts";
import _03_notifications_ohdHidFFIj from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/03.notifications.ts";
import _04_tooltip_LD8CWP4Lfg from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/04.tooltip.ts";
import user_0y9HlFsUwP from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/id/plugins/user.ts";
export default [
  revive_payload_client_nUGef6glBa,
  unhead_clwtyRzhUA,
  router_8ILKcSZw7h,
  payload_client_qE03i9JlSr,
  check_outdated_build_client_bPmvcT7J1j,
  plugin_vue3_d0NuRMK76x,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bnopKMg6f5,
  primevue_plugin_egKpok8Auk,
  plugin_client_vAlB0x9EaS,
  plugin_client_Ko7ITHlbQv,
  i18n_jw9DC0cPzU,
  chunk_reload_client_smILZi5sC6,
  _01_sentry_axzTOhSqTR,
  _02_tokens_wrFGe2jZkz,
  _03_notifications_ohdHidFFIj,
  _04_tooltip_LD8CWP4Lfg,
  user_0y9HlFsUwP
]