<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <component
    :is="props.tag"
    @click="onClick"
    v-html="props.content"
  />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    tag?: string
    content: string
  }>(),
  {
    tag: "p",
  }
)

const router = useRouter()

function onClick(e: MouseEvent) {
  if (e.metaKey || e.shiftKey || e.ctrlKey || e.altKey) {
    return
  }

  const target = e.target as HTMLElement
  if (target.tagName !== "A") {
    return
  }

  const link = target as HTMLAnchorElement
  if (link.hostname !== location.hostname) {
    return
  }

  if (link.target && /\b_blank\b/i.test(link.target)) {
    return
  }

  if (link.pathname === location.pathname && link.search === location.search) {
    return
  }

  e.preventDefault()
  router.push(link.pathname + link.search + link.hash)
}
</script>
